































import { Component, Prop, Vue } from 'vue-property-decorator';

import FormviewLayout from '@/components/utils/FormviewLayout.vue';

@Component({
  components: {
    FormviewLayout
  }
})
export default class IpAddressRestrictionErrorConfirm extends Vue {
  @Prop({ required: true})
  dialog!: boolean;

  @Prop({ required: true})
  stay!: () => void;

  @Prop({ required: true})
  leave!: () => void;
}
