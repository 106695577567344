









import { Component, Inject, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import  Config  from '@/api/models/config';
import IpAddressRestrictionErrorShowTemplate from '@/components/IpAddressRestrictionErrorShowTemplate.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';;

@Component({
  components: {
    IpAddressRestrictionErrorShowTemplate
  }
})
export default class IpAddressRestrictionErrorShow extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  dialog: boolean = true;

  config!: Config;

  async created() {
    await this.globalLoadingScreenModule.track(
      this.getConfig(),
    );
  }

  async getConfig() {
    this.config = await this.api.getConfig();
  }

  onStay() {
    this.dialog = false;
  }
  
  onLeave() {
    window.location.href = this.config.userServicesUrl;
  }
}
