








import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import  Config  from '@/api/models/config';
import IpAddressRestrictionErrorConfirm from '@/components/IpAddressRestrictionErrorConfirm.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';;

@Component({
  components: {
    IpAddressRestrictionErrorConfirm
  }
})
export default class IpAddressRestrictionErrorShowTemplate extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Prop({ required: true})
  dialog!: boolean;

  @Prop({ required: true})
  config!: Config;

  @Prop({ required: true})
  stay!: () => void;

  @Prop({ required: true})
  leave!: () => void;
}
